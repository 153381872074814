<template>
        <v-container fluid>
            contacts
        </v-container>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>